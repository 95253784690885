/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { compose } from 'recompose';
import styledSystem from '@emotion/styled';
import { graphql, navigate } from 'gatsby';
import LayoutWithAuth from '../components/layoutWithAuth';
import { withAuthorization, withEmailVerification } from '../components/Session';
import { VsaServersList } from '../components/VsaServers';
import * as ROLES from '../constants/roles';
import { Box, Flex } from '../style/basicStyle';

const VsaServersPageBase = (props) => (
  <Box px={[2, 3, 5]}>
    <VsaServersList props={props} />
  </Box>
);

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const VsaServersPage = compose(withEmailVerification, withAuthorization(condition))(VsaServersPageBase);

export default ({ toggleNavDark, location }) => {
  return (
    <Box py={[5]}>
      <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
        <VsaServersPage />
      </LayoutWithAuth>
    </Box>
  );
};

export const pageQuery = graphql`
  query vsaServersContentQuery {
    placeholderImage: imageSharp(original: { src: { regex: "/placeholderImage/" } }) {
      fluid(maxWidth: 1700, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    heroImage: imageSharp(original: { src: { regex: "/vsaServersHero/" } }) {
      fluid(maxWidth: 1500, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    pageData: allPageDataJson(filter: { name: { eq: "vsaServers" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
