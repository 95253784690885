/* eslint-disable no-undef, react/prop-types */
import { confirmAlert } from 'react-confirm-alert'; // Import
import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import toast from '../../../utils/toast';

function deleteVsa({ firebase, vsaIds, allVsa, firebaseInit }) {
  toast(`Delete VSAs initiated...`);
  firebase.auth.currentUser
    // eslint-disable-next-line react/prop-types
    .getIdToken(/* forceRefresh */ true)
    .then(function getIdToken(idToken) {
      const values = { idToken, vsaIds, allVsa };
      fetch('/.netlify/functions/deleteVsa', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
      })
        .then((result) => {
          try {
            const resultJSON = result.json();
            resultJSON
              .then(function processResult(r) {
                if (result.status === 200) {
                  const serverChecks = r.message;
                  const returnMsg = `Delete VSAs completed for ${serverChecks} servers....`;
                  console.log(returnMsg);
                  toast(returnMsg);
                  try {
                    firebaseInit('reInit');
                  } catch (e) {
                    console.log('DeleteVsa error trying to re-retrieve results: ', e);
                  }
                } else {
                  console.log('delete vsa ERROR 1 - submitted values: ', values, ' | error is: ', r);
                  toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                }
              })
              .catch((error) => {
                console.log('delete vsa ERROR 2 - likely hit 10 second timeout of function - submitted values: ', values, ' | error is: ', error);
                // toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
              });
          } catch (e) {
            console.log('delete vsa ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
            toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
          }
        })
        .catch((error) => {
          console.log('delete vsa ERROR 3 - submitted values: ', values, ' | error is: ', error);
          toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
        });
    })
    .catch(function getIdTokenError(error) {
      console.log('delete vsa - getIdTokenError - error is: ', error);
      toast(`Error downloading list - error is: ${JSON.stringify(error)}`);
    });
}

const DeleteVsa = ({ firebaseInit, data, props, selectedFlatRows, tableProps, headerGroups, getToggleAllRowsSelectedProps, getToggleAllPageRowsSelectedProps }) => {
  // console.log('DeleteVsa getToggleAllRowsSelectedProps: ', getToggleAllRowsSelectedProps);
  // console.log('DeleteVsa getToggleAllPageRowsSelectedProps: ', getToggleAllPageRowsSelectedProps);
  let allRowsSelected = false;
  if (getToggleAllPageRowsSelectedProps.title === 'Toggle All Current Page Rows Selected' && getToggleAllPageRowsSelectedProps.checked) {
    allRowsSelected = true;
  }
  const { firebase } = props;

  // console.log('DeleteVsa - data: ', data, ' || props: ', props, ' || firebaseInit: ', firebaseInit);
  // eslint-disable-next-line array-callback-return,consistent-return
  const vsaIds = selectedFlatRows.map((record) => {
    if (record && record.original && record.original.id) {
      return record.original.id;
    }
  });
  // console.log(`DeleteVsa - allRowsSelected: ${allRowsSelected} || selected VSA Ids: ${vsaIds}`);

  const optionsAll = {
    title: 'Are you sure?',
    message: `Are you sure you want to run this operation on ALL ${data.length} VSA SERVERS?`,
    buttons: [
      {
        label: 'Yes',
        onClick: () => deleteVsa({ firebase, vsaIds, allVsa: true })
      },
      {
        label: 'No',
        onClick: () =>
          function clickedNo() {
            console.log('DeleteVsa - user said no to running on all!');
          }
      }
    ],
    childrenElement: () => <div />,
    // customUI: ({ onClose }) => <div>Custom UI</div>,
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {}
    // overlayClassName: 'overlay-custom-class-name'
  };

  const options = {
    title: 'Are you sure?',
    message: `Are you sure you want to run this operation on ${data.length} VSA SERVERS?`,
    buttons: [
      {
        label: 'Yes',
        onClick: () => deleteVsa({ firebase, vsaIds })
      },
      {
        label: 'No',
        onClick: () =>
          function clickedNo() {
            console.log('DeleteVsa - user said no to running on all!');
          }
      }
    ],
    childrenElement: () => <div />,
    // customUI: ({ onClose }) => <div>Custom UI</div>,
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {}
    // overlayClassName: 'overlay-custom-class-name'
  };
  return (
    <button
      style={{ paddingLeft: '10px' }}
      type="button"
      onClick={() => {
        try {
          // eslint-disable-next-line react/prop-types
          if (allRowsSelected) {
            return confirmAlert(optionsAll);
          } else if (selectedFlatRows && selectedFlatRows.length) {
            return confirmAlert(options);
          } else {
            return toast('Please select a VSA server...');
          }
        } catch (e) {
          console.log('delete vsa - uncaught error - error is: ', e);
          return toast(`Error downloading list - error is: ${JSON.stringify(e.message)}`);
        }
      }}
    >
      Delete VSA
    </button>
  );
};

export default DeleteVsa;
