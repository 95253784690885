/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { usePagination, useSortBy, useTable, useRowSelect } from 'react-table';
import { withFirebase } from '../Firebase';
import { Box, BoxInline } from '../../style/basicStyle';
import toast from '../../utils/toast';
import OnlineCheck from './Buttons/OnlineCheck';
import VulnerabilityCheck from './Buttons/VulnerabilityCheck';
import ExploitCheck from './Buttons/ExploitCheck';
import ExploitFix from './Buttons/ExploitFix';
import DeleteVsa from './Buttons/DeleteVsa';

const TableStyles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const ButtonStyles = styled.div`
  button {
    font-size: 16px;
    padding-left: 20px;
    margin-left: 20px;
  }
`;

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

function Table({ columns, data, initialState, props, firebaseInit }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
    getToggleAllPageRowsSelectedProps,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsVar) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // eslint-disable-next-line no-shadow
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columnsVar
      ]);
    }
  );

  // Render the UI for your table
  return (
    <>
      <Box my={2} mx={[1]}>
        <BoxInline py={[1]}>
          <ButtonStyles>
            <OnlineCheck
              firebaseInit={firebaseInit}
              data={data}
              props={props}
              selectedFlatRows={selectedFlatRows}
              tableProps={getTableProps()}
              headerGroups={headerGroups}
              getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps()}
              getToggleAllPageRowsSelectedProps={getToggleAllPageRowsSelectedProps()}
            />
            <VulnerabilityCheck
              firebaseInit={firebaseInit}
              data={data}
              props={props}
              selectedFlatRows={selectedFlatRows}
              tableProps={getTableProps()}
              headerGroups={headerGroups}
              getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps()}
              getToggleAllPageRowsSelectedProps={getToggleAllPageRowsSelectedProps()}
            />
            <ExploitCheck
              firebaseInit={firebaseInit}
              data={data}
              props={props}
              selectedFlatRows={selectedFlatRows}
              tableProps={getTableProps()}
              headerGroups={headerGroups}
              getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps()}
              getToggleAllPageRowsSelectedProps={getToggleAllPageRowsSelectedProps()}
            />
            <ExploitFix
              firebaseInit={firebaseInit}
              data={data}
              props={props}
              selectedFlatRows={selectedFlatRows}
              tableProps={getTableProps()}
              headerGroups={headerGroups}
              getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps()}
              getToggleAllPageRowsSelectedProps={getToggleAllPageRowsSelectedProps()}
            />
            <DeleteVsa
              firebaseInit={firebaseInit}
              data={data}
              props={props}
              selectedFlatRows={selectedFlatRows}
              tableProps={getTableProps()}
              headerGroups={headerGroups}
              getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps()}
              getToggleAllPageRowsSelectedProps={getToggleAllPageRowsSelectedProps()}
            />
          </ButtonStyles>
        </BoxInline>
      </Box>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box my={3}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGoto = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGoto);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 50, 100, 1000].map((pageSizeNew) => (
            <option key={pageSizeNew} value={pageSizeNew}>
              Show {pageSizeNew}
            </option>
          ))}
        </select>
        {/*
        <pre>
          <code>
            {JSON.stringify(
              {
                selectedRowIds,
                'selectedFlatRows[].original': selectedFlatRows.map((d) => d.original)
              },
              null,
              2
            )}
          </code>
        </pre>
         */}
      </Box>
    </>
  );
}

const vsaServer = (user) => {
  return {
    id: user.id,
    docId: user.docId,
    vsaHost: user.vsaHost,
    customerId: user.customerId,
    lastOnlineAt: user.lastOnlineAt,
    vulnerabilityFixed: user.vulnerabilityFixed,
    vulnerabilityFixedAt: user.vulnerabilityFixedAt,
    vulnerabilityDetected: user.vulnerabilityDetected,
    vulnerabilityDetectedAt: user.vulnerabilityDetectedAt,
    exploitDetected: user.exploitDetected,
    exploitDetectedAt: user.exploitDetectedAt,
    createdAt: user.createdAt,
    updatedAt: user.updatedAt,
    httpCheck: user.httpCheck,
    httpsCheck: user.httpsCheck,
    agentCheck: user.agentCheck
  };
};

const columns = [
  {
    Header: 'VSA Servers',
    columns: [
      {
        Header: 'Hostname',
        accessor: 'vsaHost',
        Cell: ({ row: { original } }) => {
          const { vsaHost } = original;
          return (
            <a rel="noreferrer" target="_blank" href={`https://${vsaHost}`}>
              {vsaHost}
            </a>
          );
        }
      },
      {
        Header: 'Customer',
        accessor: 'customerId'
      },
      {
        Header: 'Last Online',
        accessor: 'lastOnlineAt'
      },
      {
        Header: 'Ports',
        accessor: 'ports',
        Cell: ({ row: { original } }) => {
          const { httpCheck } = original;
          const { httpsCheck } = original;
          const { agentCheck } = original;
          if (httpCheck || httpsCheck || agentCheck) {
            return (
              <>
                {httpCheck && '80 '}
                {httpsCheck && '443 '}
                {agentCheck && '5721'}
              </>
            );
          } else {
            return <></>;
          }
        }
      },
      {
        Header: 'Vulnerability Fixed',
        accessor: 'vulnerabilityFixed'
      },
      {
        Header: 'Vulnerability Fixed At',
        accessor: 'vulnerabilityFixedAt'
      },
      {
        Header: 'Vulnerability Detected',
        accessor: 'vulnerabilityDetected'
      },
      {
        Header: 'Vulnerability Detected At',
        accessor: 'vulnerabilityDetectedAt'
      },
      {
        Header: 'Exploit Detected',
        accessor: 'exploitDetected'
      },
      {
        Header: 'Exploit Detected At',
        accessor: 'exploitDetectedAt'
      },
      {
        Header: 'Added',
        accessor: 'createdAt'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt'
      }
    ]
  }
];

function getVsaData(self) {
  self.props.firebase.auth.currentUser
    // eslint-disable-next-line react/prop-types
    .getIdToken(/* forceRefresh */ true)
    .then(function getIdToken(idToken) {
      const values = { idToken };
      fetch('/.netlify/functions/getVSAs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
      })
        .then((result) => {
          try {
            const resultJSON = result.json();
            resultJSON
              .then(function processResult(r) {
                if (result.status === 200) {
                  self.setState({
                    // eslint-disable-next-line react/no-unused-state
                    vsaServers: r.message,
                    loading: false,
                    idToken
                  });
                } else {
                  console.log('getUsers request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                  toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                }
              })
              .catch((error) => {
                console.log('getUsers request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
              });
          } catch (e) {
            console.log('getUsers request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
            toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
          }
        })
        .catch((error) => {
          console.log('getUsers request ERROR 3 - submitted values: ', values, ' | error is: ', error);
          toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
        });
    })
    .catch(function getIdTokenError(error) {
      console.log('getUsers request - getIdTokenError - error is: ', error);
    });
}

class VsaServersList extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      vsaServers: []
    };
  }

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  firebaseInit = (reInit) => {
    if (this.props.firebase && (reInit || !this._initFirebase)) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      if (reInit) {
        getVsaData(self);
      } else {
        this.props.firebase.auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            getVsaData(self);
          }
        });
      }
    }
  };

  formatTable() {
    const { state } = this;
    const { vsaServers } = state;
    const length = vsaServers && vsaServers.length;
    const formatTableLevel = (depth = 0) => {
      return vsaServers.map((user) => {
        return {
          ...vsaServer(user),
          subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
        };
      });
    };
    return formatTableLevel();
  }

  render() {
    const { loading } = this.state;
    const { props } = this;
    return (
      <>
        {(loading && <div>Loading ...</div>) || <div>Select a server and select an action.</div>}
        <TableStyles>
          <Table
            firebaseInit={this.firebaseInit}
            props={props}
            columns={columns}
            data={this.formatTable()}
            initialState={{
              pageIndex: 0,
              pageSize: 100,
              sortBy: [
                {
                  id: 'lastOnlineAt',
                  desc: true
                }
              ]
            }}
          />
        </TableStyles>
      </>
    );
  }
}

export default withFirebase(VsaServersList);
